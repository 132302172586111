<template>
  <div class="card card-custom card-shadowless">
    <div class="card-body p-10">
      <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
        <div class="alert-text text-body d-flex align-items-center">
          <span class="svg-icon svg-icon-warning pr-2">
            <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
          </span>
          <span class="font-weight-bold">{{ error.message }}</span>
        </div>
      </div>
      
      <div v-else>
        <div class="d-flex flex-row align-items-center justify-content-between mb-10">
          <div>
            <h2 class="font-weight-bolder text-dark mb-0 mt-5">
              {{ experience.name.tr }}
            </h2>
          </div>
        </div>
        <ExperienceCalendar 
          :experience-id="experienceId" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from '@/core/services/api.service';
import ExperienceCalendar from "@/view/pages/experience/components/ExperienceCalendar";

export default {
  name: "ExperienceCalendarWrapper",
  components: {
    ExperienceCalendar
  },
  data() {
    return {
      experience: {
        theme: null,
        status: null,
        serviceType: null,
        name: {
          tr: '',
          en: ''
        }
      },
      experienceId: this.$route.params.id,
      error: {
        status: false,
        message: 'Beklenmedik bir hatayla karşılaştık. Lütfen daya sonra tekrar deneyin.'
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "ExperienceCalendarWrapper" }]);
    this.getExperience();
  },
  methods: {
    getExperience() {
      ApiService.get(`experience/calendar/experience/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.experience.theme = data.experience.theme;
        this.experience.serviceType = data.experience.serviceType;
        this.experience.status = data.experience.status;
        this.experience.name = data.experience.name;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    }
  }
}
</script>